






import { Component, Vue } from "vue-property-decorator";
import RegisterComponent from "@/components/auth/RegisterComponent.vue";

@Component({
  components: {
    RegisterComponent
  }
})
export default class RegisterView extends Vue {}
