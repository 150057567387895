












































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";
import Formulario from "@/components/Socios/Formulario.vue";

@Component({
  components: {
    Formulario
  }
})
export default class Socios extends Mixins(FormValidator) {
  private rutConsulta: string = "";
  private rutFormulario: string = "";
  private cleanRutConsulta: string = "";
  private cleanRutFormulario: string = "";
  private dialogEstado: boolean = false;
  private verForm: boolean = false;
  private convenioConsulta: any = {
    estado: 0,
    nombre: "",
    email: "",
    fecha_revision: ""
  };
  private codigo: string = "";

  private dataConvenio = {
    nombre: "",
    rut: "",
    tipo_empresa: "",
    email: "",
    nombre_representante: "",
    email_representante: "",
    telefono_uno: "",
    telefono_dos: "",
    direccion: "",
    aclaratoria: "",
    categoria: "",
    cobertura: "",
    descripcion_corta: "",
    web: "",
    instagram: "",
    facebook: "",
    estado: "",
    observaciones: "",
    fecha_revision: "",
    documento_sii: "",
    documento_patente: "",
    documento_sanitaria: "",
    documento_representante: "",
    documento_logo: "",
    documentoSii_ext: "",
    documentoPatente_ext: "",
    documentoSanitaria_ext: "",
    documentoRepresentante_ext: "",
    documentoLogo_ext: ""
  };

  @Watch("rutConsulta")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.cleanRutConsulta = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutConsulta = `${rut}-${dv}`;
    } else {
      this.rutConsulta = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("rutFormulario")
  private formatRutForm(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.cleanRutFormulario = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutFormulario = `${rut}-${dv}`;
    } else {
      this.rutFormulario = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  get estadoConsulta() {
    if (this.convenioConsulta.estado == 1) {
      return this.$t("status.review");
    } else if (this.convenioConsulta.estado == 2) {
      return this.$t("status.correction");
    } else if (this.convenioConsulta.estado == 3) {
      return this.$t("status.approved");
    } else if (this.convenioConsulta.estado == 4) {
      return this.$t("status.rejected");
    }
    return "";
  }

  get colorEstado() {
    if (this.convenioConsulta.estado == 1) {
      return "primary";
    } else if (this.convenioConsulta.estado == 2) {
      return "warning";
    } else if (this.convenioConsulta.estado == 3) {
      return "positive";
    } else if (this.convenioConsulta.estado == 4) {
      return "negative";
    }
    return "grey";
  }

  private getEstado() {
    if (!this.validateRut(this.cleanRutConsulta)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.NO_RUT").toString(),
        confirmButtonText: this.$t("buttons.accept").toString(),
        confirmButtonColor: "#00609a"
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("check/rut/estado/solicitud?rut=" + this.cleanRutConsulta)
      .then(res => {
        this.convenioConsulta = res.data;
        this.dialogEstado = true;
        this.$q.loading.hide();
      })
      .catch(error => {
        this.$q.loading.hide();
        if (error.response.data.message == "RUT_SIN_SOLICITUD") {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.RUT_UNUSED_CONVENIO", {
              rut: this.rutConsulta
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a"
          });
        } else {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a"
          });
        }
      });
    this.$q.loading.hide();
  }

  private checkNewRut() {
    if (!this.validateRut(this.cleanRutFormulario)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.NO_RUT").toString(),
        confirmButtonText: this.$t("buttons.accept").toString(),
        confirmButtonColor: "#00609a"
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("check/rut/convenio?rut=" + this.cleanRutFormulario)
      .then(res => {
        this.verForm = true;
        this.$q.loading.hide();
      })
      .catch(error => {
        this.verForm = false;
        this.$q.loading.hide();
        if (error.response.data.message == "RUT_CON_SOLICITUD") {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.RUT_USED_CONVENIO", {
              rut: this.rutConsulta
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a"
          });
        } else if (error.response.data.message == "RUT_INSCRITO") {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.RUT_INSCRITO", {
              rut: this.rutConsulta
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a"
          });
        } else {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a"
          });
        }
      });
  }

  private validarCodigo() {
    if (!this.codigo || this.codigo == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.solicitud").toString(),
        text: this.$t("notifications.error.NO_CODE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString(),
        confirmButtonColor: "#00609a"
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .post("validar/codigo", {
        rut: this.cleanRutConsulta,
        codigo: this.codigo
      })
      .then(res => {
        this.$q.loading.hide();
        this.dataConvenio = res.data;
        this.verForm = true;
        this.dialogEstado = false;
      })
      .catch(error => {
        this.$q.loading.hide();
        this.dialogEstado = false;
        if (error.response.data.message == "RUT_SIN_SOLICITUD") {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.RUT_UNUSED_CONVENIO", {
              rut: this.rutConsulta
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a"
          });
        } else if (error.response.data.message == "INVALID_CODIGO") {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.INVALID_CODE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a",
            didDestroy: () => {
              this.dialogEstado = true;
            }
          });
        } else {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.solicitud").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString(),
            confirmButtonColor: "#00609a"
          });
        }
      });
  }
  private rutSanitize(rut: string) {
    rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = rut.substring(0, rut.length - 1);
    const dv = rut.substring(rut.length - 1);
    return `${rutNumber}-${dv}`;
  }

  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
