



















































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";
export default class HomeBeneficios extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;

  private irConvenios() {
    this.$router
      .push({
        name: "Categorias"
      })
      .catch(err => {
        console.log(err);
      });
  }
}
