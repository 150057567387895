






import { Vue, Component, Prop } from "vue-property-decorator";
import Convenios from "@/components/Beneficios/Convenios.vue";

@Component({
  components: {
    Convenios
  }
})
export default class ConveniosView extends Vue {
  private mounted() {}
}
