

















































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

@Component
export default class Requisitos extends Vue {
  private irLink(link: any) {
    window.open(link, "_blank");
  }
}
