






import { Vue, Component, Prop } from "vue-property-decorator";
import Preguntas from "@/components/Preguntas/PreguntasFrecuentes.vue";

@Component({
  components: {
    Preguntas
  }
})
export default class PreguntasFrecuentesView extends Vue {
  private mounted() {}
}
