
















































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";

@Component
export default class Categorias extends Mixins(FormValidator) {
  private categorias: Array<any> = [];
  private mounted() {
    // this.$gtag.event("trazabilidad_categorias_convenios",{
    //   event_label: "trazabilidad_categorias_convenios",
    //   event_category: "test",
    //   value: 1,
    // });
    this.getCategorias();
  }

  private getCategorias() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/listado/categorias")
      .then((res: any) => {
        this.categorias = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.$q.loading.hide();
      });
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categorias" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Comercios",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
