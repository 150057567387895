


























































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";

@Component
export default class Convenios extends Mixins(FormValidator) {
  private comercios: Array<any> = [];
  private nombreWeb: any = "";
  private nombreCategoria: any = "";
  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoComercios();
    }
  }

  private getInfoComercios() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("/listado/convenios?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.comercios = res.data;
        for (let i = 0; i < this.comercios.length; i++) {
          if (this.comercios[i].foto != null) {
            this.comercios[i].foto =
              " https://colina-docs.tarjetavecino.com/" +
              this.comercios[i].foto;
          }
        }
        this.nombreCategoria = this.comercios[0].nombre_categoria;

        //     this.$gtag.event("trazabilidad_convenios_ " + this.nombreCategoria, {
        //   event_label: "trazabilidad_convenios_ " + this.nombreCategoria,
        //   event_category: "test",
        //   value: 1,
        // });
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        console.log(err);
        this.routerGo("Categorias");
        this.$q.loading.hide();
      });
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }

  private irBeneficio(idComercio: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Beneficio" &&
      this.$router.currentRoute.params.name == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Beneficio",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
