






import { Vue, Component, Prop } from "vue-property-decorator";
import DetalleEmp from "@/components/Emprendimientos/DetalleEmp.vue";

@Component({
  components: {
    DetalleEmp
  }
})
export default class DetalleEmpView extends Vue {
  private mounted() {}
}
