






import { Component, Vue } from "vue-property-decorator";
import RecoverPassComponent from "@/components/auth/RecoverPassComponent.vue";

@Component({
  components: {
    RecoverPassComponent
  }
})
export default class RecoverPassView extends Vue {}
