import { render, staticRenderFns } from "./Socios.vue?vue&type=template&id=4db86cf4&scoped=true&"
import script from "./Socios.vue?vue&type=script&lang=ts&"
export * from "./Socios.vue?vue&type=script&lang=ts&"
import style0 from "./Socios.vue?vue&type=style&index=0&id=4db86cf4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db86cf4",
  null
  
)

export default component.exports